import { React, useState, useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { useDataProvider, Loading, useCreatePath } from 'react-admin'

import TabPanel from 'components/TabPanel'
import { StyledLink } from 'components/styled/Link'

const BooksTab = (props) => {
  const createPath = useCreatePath()
  const dataProvider = useDataProvider()
  const [books, setBooks] = useState([])
  const [isLoading, setLoading] = useState(true)
  const { record, value } = props
  const isActive = value === 1
  useEffect(() => {
    if (isActive) {
      setLoading(true)
      dataProvider.getList('rule_books',
        {
          pagination: { page: 1, perPage: 1000 },
          filter: { rules: [record.id] }
        }
      ).then(({ data }) => {
        setBooks(data)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        throw new Error('cannot load books', err)
      })
    }
  }, [isActive])

  if (!isActive) {
    return null
  }
  if (isLoading) {
    return (<Loading />)
  }
  return (
    <TabPanel {...props}>
      <Stack spacing={1}>
        {
          books.map((book) => {
            return (
              <StyledLink key={book.id} to={createPath({
                resource: 'rule_books', id: book.id, type: 'edit'
              })}>
                <Typography sx={{ color: '#1976d2' }} variant='span'>{book.name}</Typography>
              </StyledLink>
            )
          })
        }
      </Stack>
    </TabPanel>
  )
}

export default BooksTab
