import React from 'react'
import { useLocation } from 'react-router-dom'
import { Layout, Loading, useGetList, useRedirect } from 'react-admin'
import AppBar from 'components/layout/AppBar'
import idToken from 'inMemory/idToken'
import product from 'inMemory/product'
import { NO_PRODUCTS_ROUTE } from 'pages/NoProducts'

const RootLayout = (props) => {
  const products = idToken.getToken()['custom:products']

  const location = useLocation()
  const redirect = useRedirect()
  React.useEffect(() => {
    if (!products && location.pathname !== NO_PRODUCTS_ROUTE) {
      redirect(NO_PRODUCTS_ROUTE)
    }
  })

  const listProducts = products ? products.split(',') : []
  const alias = localStorage.getItem('selectedProduct') || listProducts[0]

  const { data: domainData, isLoading: isDomainLoading } = useGetList('domains', {
    filter: { alias }
  })

  if (isDomainLoading) {
    return <Loading />
  }

  if (domainData && domainData.length > 0) {
    const { company, workspace, application, domain_id: domainId, id } = domainData[0]
    product.setApplication(application)
    product.setCompany(company)
    product.setWorkspace(workspace)
    product.setDomainId(domainId)
    product.setId(id)
    localStorage.setItem('selectedProduct', alias)
  }

  return <Layout {...props}
    appBar={(props) => <AppBar {...props} userProducts={listProducts}/>}
    />
}

export default RootLayout
