import { React, useState } from 'react'
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Grid
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DataObjectIcon from '@mui/icons-material/DataObject'
import PaymentsIcon from '@mui/icons-material/Payments'
import GroupsIcon from '@mui/icons-material/Groups'
import LoopIcon from '@mui/icons-material/Loop'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import BadgeIcon from '@mui/icons-material/Badge'
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined'
import MoneyIcon from '@mui/icons-material/Money'
import { LocalAtm } from '@mui/icons-material'

import { a11yProps, TabPanel } from 'components/TabPanel'
import { CodeBlockField } from 'common/fields'

const AnalysesTabs = ({ record }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Overview" icon={<RemoveRedEyeIcon/>} iconPosition="start" {...a11yProps(0)} />
          <Tab label="All Data" icon={<DataObjectIcon/>} iconPosition="start" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <OverviewTab value={value} index={0} record={record} />
      <AllDataTab value={value} index={1} record={record} />
    </Box>

  )
}

const OverviewTab = (props) => {
  const { record, ...otherProps } = props
  return (
    <TabPanel {...otherProps}>
      <Grid container spacing={2}>
        <Grid item sx={4}>
          <Box display="flex">
              <PaymentsIcon fontSize="large" color='light-grey'/>
              <Box ml={2}>
                <Typography variant="h8" color="textSecondary">Payment Id</Typography>
                <Typography variant="body2">
                  {record.payment_id || 'Not available'}
                </Typography>
              </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
              <GroupsIcon fontSize="large" color='light-grey'/>
              <Box ml={2}>
                <Typography variant="h8" color="textSecondary">Correlation Id</Typography>
                <Typography variant="body2">
                  {record.correlation_id || 'Not available'}
                </Typography>
              </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
            <LoopIcon fontSize="large" color='light-grey'/>
            <Box ml={2}>
              <Typography variant="h8" color="textSecondary">Cycle Id</Typography>
              <Typography variant="body2">
                {record.cycle_id || 'Not available'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
            <MoneyIcon fontSize="large" color='light-grey'/>
            <Box ml={2}>
              <Typography variant="h8" color="textSecondary">Amount</Typography>
              <Typography variant="body2">
                {record.context.transaction?.amount || 'Not avaliable'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
            <LocalAtm fontSize="large" color='light-grey'/>
            <Box ml={2}>
              <Typography variant="h8" color="textSecondary">Payment Type</Typography>
              <Typography variant="body2">
                {record.context.transaction?.payment_type || 'Not avaliable'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
            <CreditCardIcon fontSize="large" color='light-grey'/>
            <Box ml={2}>
              <Typography variant="h8" color="textSecondary">Card Token</Typography>
              <Typography variant="body2">
                {record.context.transaction?.card_token || 'Not avaliable'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
              <PhoneAndroidIcon fontSize="large" color='light-grey'/>
              <Box ml={2}>
                <Typography variant="h8" color="textSecondary">Customer MSISDN</Typography>
                <Typography variant="body2">
                  {record.context.customer?.msisdn || 'Not avaliable'}
                </Typography>
              </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
          <Box display="flex">
              <BadgeIcon fontSize="large" color='light-grey'/>
              <Box ml={2}>
                <Typography variant="h8" color="textSecondary">Customer Document</Typography>
                <Typography variant="body2">
                  {record.context.customer?.document || 'Not avaliable'}
                </Typography>
              </Box>
          </Box>
        </Grid>
        <Grid item sx={4}>
            <Box display="flex">
              <FingerprintOutlinedIcon fontSize="large" color='light-grey'/>
              <Box ml={2}>
                <Typography variant="h8" color="textSecondary">Customer Id</Typography>
                <Typography variant="body2">
                  {record.context.customer?.customer_id || 'Not avaliable'}
                </Typography>
              </Box>
            </Box>
        </Grid>
      </Grid>
  </TabPanel>
  )
}

const AllDataTab = (props) => {
  const { record, ...otherProps } = props
  return (
    <TabPanel {...otherProps}>
      <CodeBlockField record={record} source={null} language="javascript"/>
    </TabPanel>
  )
}
export default AnalysesTabs
