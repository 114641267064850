import React from 'react'
import {
  Title,
  ListBase,
  Pagination,
  useGetResourceLabel,
  useListContext
} from 'react-admin'
import {
  Box,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputOutlinedIcon from '@mui/icons-material/InputOutlined'
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined'

import { CodeBlockField } from 'common/fields'
import StyledBadge from 'components/styled/Badge'
import EventsHeader from 'resources/events/EventsHeader'
import EventsSidebar from 'resources/events/EventsSidebar'

const ListView = () => {
  const { data } = useListContext()

  if (!data) return null

  return (
    <Grid spacing={2} container>
      {data.map((record) => (
        <Grid item key={record.id} sm={12} md={6} lg={12}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <EventsHeader record={record} />
            <CardContent>
              <Box style={{ marginBottom: 8 }}>
              <StyledBadge badgeContent="id" color='error'>
                <FingerprintOutlinedIcon />
              </StyledBadge>
              <Chip label={record.id} style={{ marginLeft: 4 }}/>
              </Box>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                    <InputOutlinedIcon style={{ marginRight: 8 }} /> {/* Icon with some right margin */}
                    Input
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeBlockField record={record} source="input" language="javascript"/>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                    <InputOutlinedIcon style={{ marginRight: 8 }} /> {/* Icon with some right margin */}
                    Output
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CodeBlockField record={record} source="output" language="javascript"/>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>

        </Grid>
      ))}

    </Grid>
  )
}

const EventsList = () => {
  const getResourceLabel = useGetResourceLabel()

  return (
    <ListBase perPage={10}>
        <Title defaultTitle={getResourceLabel('events', 2)} />
        <Box display="flex">
          <EventsSidebar />
          <Box width={'calc(100% - 16em)'}>
            <ListView />
            <Pagination rowsPerPageOptions={[10, 20, 30]} />
          </Box>
        </Box>
    </ListBase>
  )
}

export default EventsList
