import * as React from 'react'

import { Admin, Resource, CustomRoutes } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'

import authProvider from 'adapters/authprovider'
import dataProvider from 'adapters/dataprovider'
import RootLayout from 'components/layout/RootLayout'
import rules from 'resources/rules'
import books from 'resources/rule_books'
import analyses from 'resources/analyses'
import domains from 'resources/domains'
import blocklists from 'resources/blocklists'
import events from 'resources/events'
import { NoProducts, NO_PRODUCTS_ROUTE } from 'pages/NoProducts'

const App = () => {
  return (
    <BrowserRouter>
      <Admin
        layout={RootLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        requireAuth>
          <Resource name='domains' {...domains} />
          <Resource name='rules' {...rules} />
          <Resource name='rule_books' {...books} />
          <Resource name='blocklists' {...blocklists} />
          <Resource name='events' {...events} options={{ label: 'Audit Trail' }} />
          <Resource name='analyses' {...analyses} />

          <CustomRoutes>
            <Route path={NO_PRODUCTS_ROUTE} element={<NoProducts />}/>
          </CustomRoutes>
      </Admin>
    </BrowserRouter>)
}

export default App
