import RuleIcon from '@mui/icons-material/RuleSharp'

import RuleList from 'resources/rules/RuleList'
import RuleCreate from 'resources/rules/RuleCreate'
import RuleEdit from 'resources/rules/RuleEdit'

const resource = {
  list: RuleList,
  edit: RuleEdit,
  create: RuleCreate,
  icon: RuleIcon
}

export default resource
