import FilterInput from 'components/styled/FilterInput'
import * as React from 'react'
import ResultSelect from './ResultSelect'
import { FilterLiveStyled } from './styles'

const AnalysesFilters = [
  <FilterLiveStyled key="context.customer.customer_id" label="Customer ID" source="context.customer.customer_id" />,
  <FilterLiveStyled key="context.customer.msisdn" label="MSISDN" source="context.customer.msisdn" />,
  <FilterLiveStyled key="context.customer.document" label="Document" source="context.customer.document" />,
  <FilterLiveStyled key="context.transaction.card_token" label="Card Token" source="context.transaction.card_token" />,
  <FilterInput key="payment_id" label="Payment Id" source="payment_id" />,
  <FilterInput key="correlation_id" label="Correlation Id" source="correlation_id" />,
  <FilterInput key="cycle_id" label="Cycle Id" source="cycle_id" />,
  <ResultSelect key="result" label="Result" source="result" />,
  <FilterInput key="id" label="Id" source="id" />
]

export default AnalysesFilters
