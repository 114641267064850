import React from 'react'

import { Box, Tabs, Tab } from '@mui/material'
import TranslateIcon from '@mui/icons-material/Translate'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import { CodeBlockField } from 'common/fields'
import { a11yProps, TabPanel } from 'components/TabPanel'
import BooksTab from 'resources/rules/BooksTab'

const RuleTabs = ({ record }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="DSL Representation" icon={<TranslateIcon/>} iconPosition="start" {...a11yProps(0)} />
          <Tab label="Rule Books" icon={<MenuBookIcon/>} iconPosition="start" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
          <CodeBlockField record={record} source="dsl_representation"/>
      </TabPanel>
      <BooksTab value={value} index={1} record={record} />
    </Box>
  )
}

export default RuleTabs
