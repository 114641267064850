import React from 'react'
import { useRecordContext } from 'react-admin'

import Chip from '@mui/material/Chip'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs'

export const SimpleChipField = () => {
  const record = useRecordContext()
  return (
    <span style={{ color: 'purple' }}>
      <Chip
        key={record}
        label={record}
      />
    </span>
  )
}

export const CodeBlockField = ({ record, source, language }) => {
  const context = record ?? useRecordContext()
  if (!context) return null

  const value = source && record[source] !== undefined ? record[source] : record
  if (!value) return null

  const codeSnippet = typeof value === 'object' ? JSON.stringify(value, null, 2) : value
  return (
   <SyntaxHighlighter language={language ?? 'ruby'} style={github} wrapLongLines >
      {codeSnippet}
  </SyntaxHighlighter>
  )
}
