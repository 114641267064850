import React from 'react'
import { SelectInput } from 'react-admin'

const ResultSelect = props => {
  const choises = [
    { id: 'error', name: 'Error' },
    { id: 'authorized', name: 'Authorized' },
    { id: 'unauthorized', name: 'Unauthorized' }
  ]

  return (
    <SelectInput {...props} choices={choises}/>
  )
}

export default ResultSelect
