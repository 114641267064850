import React from 'react'
import { MenuItem, styled, Select } from '@mui/material'
import { AppBar as RaAppBar } from 'react-admin'

const AppBar = (props) => {
  const { userProducts } = props
  const selectedProduct = localStorage.getItem('selectedProduct') || userProducts[0]

  const handleChange = (event) => {
    localStorage.setItem('selectedProduct', event.target.value)
    location.reload()
  }
  return (
    <StyledAppBar>
      {userProducts.length > 0
        ? (
        <StyledSelect
          sx={{ height: '2.5rem' }}
          labelId="label"
          id="select"
          value={selectedProduct}
          onChange={handleChange}
        >
          {userProducts.map((product, index) => (
            <MenuItem key={index} value={product} selected={product === selectedProduct}>
              {product}
            </MenuItem>
          ))}
        </StyledSelect>
          )
        : (
        <div>No products available</div>
          )}
    </StyledAppBar>
  )
}

const StyledAppBar = styled(RaAppBar)`
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;

  button{
    margin: 0 0.3rem 0 0.3rem;
  }

  & .RaAppBar-menuButton {
    margin-right: auto;
  }
  & .MuiInputBase-root {
    margin-right: 1rem;
  }
`

const StyledSelect = styled(Select)`
&.MuiInputBase-root {
  color: white;
}

&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

&.MuiOutlinedInput-root {

  &:hover {
    border-color: white;
  }

  & .MuiSelect-icon {
    color: white;
  }
}

`

export default AppBar
