import { useNotify, useRefresh } from 'react-admin'

const parsePydanticError = (error) => {
  const message = []
  error.forEach(item =>
    message.push(`${item.msg} - Type: (${item.type})\n`)
  )
  return message.join()
}

const useOnError = () => {
  const notify = useNotify()
  const refresh = useRefresh()

  return (error) => {
    const isErrorString = typeof error === 'string'
    const details = isErrorString ? error : error.body.details
    const errorCode = isErrorString ? '' : error.body.error_code

    const errorMessage = errorCode
      ? `Error Code: (${errorCode})\n - Details:\n${Array.isArray(details) ? parsePydanticError(details) : details}`
      : Array.isArray(details) ? parsePydanticError(details) : details

    notify(errorMessage, { type: 'error' })
    refresh()
  }
}

export default useOnError
