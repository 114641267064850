import BookIcon from '@mui/icons-material/LibraryBooksSharp'

import BookList from 'resources/rule_books/BookList'
import BookCreate from 'resources/rule_books/BookCreate'
import BookEdit from 'resources/rule_books/BookEdit'

const resource = {
  list: BookList,
  edit: BookEdit,
  create: BookCreate,
  icon: BookIcon
}

export default resource
