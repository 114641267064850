import DomainIcon from '@mui/icons-material/LanguageSharp'

import DomainList from 'resources/domains/DomainList'
import DomainCreate from 'resources/domains/DomainCreate'
import DomainEdit from 'resources/domains/DomainEdit'

const resource = {
  list: DomainList,
  edit: DomainEdit,
  create: DomainCreate,
  icon: DomainIcon
}

export default resource
