import React from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Checkbox
} from '@mui/material'
import { TextInput, useInfiniteGetList, SimpleForm } from 'react-admin'

import { stringAvatar, stringToColor } from 'common/avatar'
import { CodeBlockField } from 'common/fields'

const RuleList = (props) => {
  const [nameFilter, setNameFilter] = React.useState('')
  const [timer, setTimer] = React.useState(null)
  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteGetList(
    'rules',
    {
      pagination: { page: 1, perPage: 10 },
      filter: { name: nameFilter }

    }
  )
  const observerElem = React.useRef(null)

  const handleObserver = React.useCallback(
    entries => {
      const [target] = entries
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage]
  )

  const handleNameFilterChange = (event) => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      setNameFilter(event.target.value)
    }, 250)
    setTimer(newTimer)
  }

  React.useEffect(() => {
    const element = observerElem.current
    if (!element) return
    const option = { threshold: 0 }
    const observer = new IntersectionObserver(handleObserver, option)
    observer.observe(element)
    return () => observer.unobserve(element)
  }, [fetchNextPage, hasNextPage, handleObserver])

  const [checked, setChecked] = React.useState([])
  const { onSelectRules } = props

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    onSelectRules(newChecked)
  }

  return (
    <>
      <List>
        <SimpleForm toolbar={null} component="div" margin={0} marginLeft={2} >
          <TextInput source="search" label="Search" onChange={handleNameFilterChange} />
        </SimpleForm>
          {data?.pages.map((page) =>
            page.data.map((rule) => {
              const labelId = `checkbox-list-secondary-label-${rule.id}`
              return (
              <>
                <ListItem
                  key={rule.id}
                  secondaryAction={
                    <Checkbox
                    edge="end"
                    onChange={handleToggle(rule.id)}
                    checked={checked.indexOf(rule.id) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: stringToColor(rule.context) }}>
                      {stringAvatar(rule.context)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={rule.name}
                    secondary={
                      <>
                        <span>{rule.description}</span>
                        <CodeBlockField record={rule} source="dsl_representation"/>
                      </>
                    }
                  />
                </ListItem>
                <Divider />
              </>
              )
            })
          )}
      </List>
      <Typography ref={observerElem} variant="body2" color="grey.500" marginLeft={2} >
        {isFetchingNextPage && hasNextPage
          ? 'Loading...'
          : 'No search left'}
      </Typography>
    </>
  )
}

export default RuleList
