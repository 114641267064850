import { styled } from '@mui/system'
import { CreateButton, ExportButton, FilterButton } from 'react-admin'

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
`

export const StyledCreateButton = styled(CreateButton)`
  min-width: 5.5rem;
  margin-right: 0.5rem;
  height: 1.75rem;
`

export const StyledExportButton = styled(ExportButton)`
  min-width: 5.5rem;
  height: 1.75rem;
`
export const StyledFilterButton = styled(FilterButton)`
  min-width: 7.5rem;
`
