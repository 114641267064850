import { React, useState } from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton, Tooltip } from '@mui/material'
import { Confirm, useRecordContext, useDataProvider, useNotify, useRefresh } from 'react-admin'
import { useMutation } from 'react-query'

const RemoveRuleButton = (props) => {
  const { book } = props
  const rule = useRecordContext()

  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const dataProvider = useDataProvider()
  const providerParams = { bookId: book.id, ruleId: { rule_id: rule.id } }
  const { mutate, isLoading } = useMutation(
    () => dataProvider.removeRule(providerParams),
    {
      onSuccess: () => {
        setOpen(false)
        notify('Rule removed', { type: 'info' })
        refresh()
      }
    }
  )

  const handleConfirm = e => {
    e.stopPropagation()
    mutate()
  }

  const handleDialogOpen = e => {
    setOpen(true)
    e.stopPropagation()
  }

  const handleDialogClose = e => {
    setOpen(false)
    e.stopPropagation()
  }

  return (<>
      <Tooltip title="Remove">
        <IconButton color="error" onClick={handleDialogOpen} >
            <RemoveCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <Confirm
        isOpen={open}
        title={`Remove rule from '${book.name}'`}
        content={`Are you sure you want to remove '${rule.name}'?`}
        loading={isLoading}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        />
  </>)
}

export default RemoveRuleButton
