import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  usePermissions
} from 'react-admin'

import BlocklistFilter from 'resources/blocklists/BlocklistFilters'
import product from 'inMemory/product'
import LocaleDateField from 'components/LocaleDateField'

const BlocklistList = () => {
  const { permissions } = usePermissions()
  const filter = permissions.includes('admin') ? {} : { domain_id: product.getDomainId() }

  return (
    <List filters={BlocklistFilter} filter={filter}>
      <Datagrid rowClick="edit">
        <ReferenceField source="domain_id" reference="domains" link={false} >
          <TextField source="alias" />
        </ReferenceField>
        <TextField source="key" />
        <TextField source="value" />
        <TextField source="action" />
        <LocaleDateField source="expired_at" locales="pt-BR" showTime emptyText="-" />
        <LocaleDateField source="created_at" locales="pt-BR" showTime emptyText="-" />
        <TextField source="id" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default BlocklistList
