import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  number,
  minValue,
  maxValue,
  SelectInput,
  BooleanInput
} from 'react-admin'
import { Grid } from '@mui/material'

import RuleName from 'resources/rules/RuleName'
import useOnError from 'common/error'

const typeChoyses = [
  { id: 'standard', name: 'Standard' },
  { id: 'custom', name: 'Custom' }
]

const transform = data => {
  delete data.hashed_dsl
  return data
}

const RuleEdit = () => {
  const onError = useOnError()
  return (
    <Edit title={<RuleName />} mutationOptions={{ onError }} transform={transform}>
        <SimpleForm>
            <TextInput source="name" fullWidth validate={required()}/>
            <TextInput source="description" fullWidth multiline validate={required()} />
            <TextInput source="dsl_representation" fullWidth multiline validate={required()} />
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={2}>
                  <NumberInput source="relevance" fullWidth
                    validate={[required(), number(), minValue(0), maxValue(1)]}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SelectInput source="type" choices={typeChoyses} validate={required()} fullWidth/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextInput source="context" validate={required()} fullWidth/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="id" validate={required()} fullWidth disabled/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <BooleanInput source="used_in_balance_calculation" label="Used in balance calculation" fullWidth/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
  )
}
export default RuleEdit
