import React from 'react'
import {
  useRecordContext
} from 'react-admin'

const RuleName = () => {
  const record = useRecordContext()
  return <span>Rule {record ? `"${record.name}"` : ''}</span>
}

export default RuleName
