import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  minValue,
  maxValue,
  number,
  SelectInput,
  usePermissions,
  BooleanInput
} from 'react-admin'
import { Grid } from '@mui/material'

import useOnError from 'common/error'

const typeCustomChoice = [
  { id: 'custom', name: 'Custom' }
]
const typeChoices = [
  { id: 'standard', name: 'Standard' },
  { id: 'custom', name: 'Custom' }
]

const RuleCreate = props => {
  const onError = useOnError()
  const { permissions } = usePermissions()
  const isAdmin = permissions.includes('admin')
  const allowedTypes = () => {
    return !isAdmin ? typeCustomChoice : typeChoices
  }

  return (<Create mutationOptions={{ onError }}>
      <SimpleForm>
          <TextInput source="name" fullWidth validate={required()}/>
          <TextInput source="description" fullWidth multiline validate={required()} />
          <TextInput source="dsl_representation" fullWidth multiline validate={required()} />
          <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <NumberInput source="relevance" fullWidth
                  validate={[
                    required(),
                    number(),
                    minValue(0),
                    maxValue(1)
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <SelectInput source="type" choices={allowedTypes()} validate={required()} fullWidth/>
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextInput source="context" validate={required()} fullWidth/>
              </Grid>
              <Grid item xs={12} sm={4}>
                  <BooleanInput source="used_in_balance_calculation" label="Used in balance calculation" fullWidth/>
                </Grid>
          </Grid>
      </SimpleForm>
  </Create>
  )
}

export default RuleCreate
