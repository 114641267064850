import React from 'react'
import { Grid } from '@mui/material'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  number,
  minValue,
  maxValue,
  regex,
  ListButton,
  TopToolbar
} from 'react-admin'

import BookName from 'resources/rule_books/BookName'
import useOnError from 'common/error'
import DomainIdEditorSelect from 'components/DomainIdEditorSelect'

const floatPriorityMsg = '`Priority` should be a positive number ranging from 1 to 1000'

const transform = data => {
  delete data.rules
  delete data.hashed_scope
  return data
}

const BookEditActions = () => (
  <TopToolbar>
      <ListButton />
  </TopToolbar>
)

const BookEdit = props => {
  const onError = useOnError()
  return (
    <Edit title={<BookName />}
          actions={<BookEditActions />}
          transform={transform}
          mutationOptions={{ onError }}
    >
        <SimpleForm>
          <TextInput source="name" multiline validate={required()} fullWidth/>
          <TextInput source="description" multiline validate={required()} fullWidth />
          <TextInput source="scope" multiline validate={required()} fullWidth/>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                  <DomainIdEditorSelect fullWidth validate={required()} optionText="name" optionValue="id" />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextInput
                  source="priority"
                  fullWidth
                  validate={[required(), number(), minValue(1),
                    maxValue(1000), regex(/^[0-9]*$/, floatPriorityMsg)]} />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextInput
                  source="id"
                  fullWidth
                  disabled />
              </Grid>
            </Grid>
            <ArrayInput source="parameters" >
                <SimpleFormIterator inline>
                    <TextInput source="k" fullWidth label="name" helperText={false} validate={required()}/>
                    <TextInput source="v" label="value" helperText={false} validate={required()}/>
                    <SelectInput
                    source="type"
                    choices={[
                      { id: 'int', name: 'int' },
                      { id: 'float', name: 'float' },
                      { id: 'str', name: 'str' }
                    ]}
                    validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
    </Edit>
  )
}

export default BookEdit
