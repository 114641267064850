
import React from 'react'
import {
  Edit,
  SimpleForm
} from 'react-admin'

import DomainName from 'resources/domains/DomainName'
import DomainInputs from 'resources/domains/DomainInputs'
import useOnError from 'common/error'

const DomainEdit = () => {
  const onError = useOnError()
  return (
  <Edit title={<DomainName />} mutationOptions={{ onError }}>
      <SimpleForm>
        <DomainInputs view="edit" />
      </SimpleForm>
  </Edit>
  )
}

export default DomainEdit
