const ProductData = () => {
  let productList
  let company
  let workspace
  let application
  let domainId
  let id

  const getListData = () => {
    return productList
  }

  const setListData = (data) => {
    if (typeof data === 'string' && data.includes(',')) {
      productList = data.split(',')
    }
  }

  const getCompany = () => {
    return company
  }

  const getWorkspace = () => {
    return workspace
  }

  const getApplication = () => {
    return application
  }

  const getDomainId = () => {
    return domainId
  }

  const getId = () => {
    return id
  }

  const setCompany = (data) => {
    company = data
  }

  const setWorkspace = (data) => {
    workspace = data
  }

  const setApplication = (data) => {
    application = data
  }

  const setDomainId = (data) => {
    domainId = data
  }

  const setId = (data) => {
    id = data
  }

  return {
    getListData,
    getCompany,
    getApplication,
    getWorkspace,
    getDomainId,
    getId,
    setListData,
    setWorkspace,
    setCompany,
    setApplication,
    setDomainId,
    setId
  }
}

export default ProductData()
