import React from 'react'
import {
  DateField,
  useRecordContext
} from 'react-admin'

const hasTimeZone = (isoDateString) => {
  return /Z|[+-]\d\d:\d\d$/.test(isoDateString)
}

const convertToGMTMinus3 = (data) => {
  const offset = -3 * 60 * 60 * 1000
  return new Date(data.getTime() + offset)
}

const LocaleDateField = ({ source, locales, showTime, ...rest }) => {
  const record = rest.record ?? useRecordContext()
  if (!record) return null

  const value = record[source]
  if (!value) return null

  const date = new Date(value)

  const transformedDate = hasTimeZone(value) ? date : convertToGMTMinus3(date)

  return (
    <DateField
      source={source}
      locales={locales}
      showTime={showTime}
      record={{ ...record, [source]: transformedDate.toISOString() }}
      {...rest}
    />
  )
}

export default LocaleDateField
