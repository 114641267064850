import React from 'react'

import {
  Stack,
  Typography
} from '@mui/material'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  minValue,
  maxValue,
  number,
  regex
} from 'react-admin'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import SettingsIcon from '@mui/icons-material/Settings'
import StorefrontIcon from '@mui/icons-material/Storefront'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'

import DomainIdAutoComplete from 'components/DomainIdAutoComplete'

const DomainInputs = ({ view }) => {
  return (
       <Stack direction="column" sx={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <AccountTreeIcon/>
            <Typography variant="h6">Domain Information</Typography>
          </Stack>
          <Stack>
            <TextInput source="alias" validate={[required(), regex(/^[a-zA-Z0-9_-]+$/, 'Alias must contain only letters, numbers, underscores, or hyphens.')]} fullWidth/>
            <TextInput source="company" validate={required()} fullWidth/>
            <TextInput source="workspace" validate={required()} fullWidth/>
            <TextInput source="application" validate={required()} fullWidth/>
            <Stack direction="row" spacing={5}>
              <ReferenceInput
                  source="domain_id"
                  reference="domains"
                  perPage={10000}
              >
                {view === 'edit'
                  ? (
                  <SelectInput fullWidth optionText="alias" label="Parent Domain" disabled sx={{ mt: 0 }}/>
                    )
                  : (
                    <DomainIdAutoComplete fullWidth optionText="alias" label="Parent Domain" />
                    )}
              </ReferenceInput>
              {view === 'edit' && (
                  <TextInput source="id" disabled fullWidth />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SettingsIcon/>
            <Typography variant="h6">Domain Configuration</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <BooleanInput source="test_ab_conf.enabled" label="A/B Testing Enabled" validate={required()}/>
            <NumberInput source="test_ab_conf.sampling"
                            label="A/B Testing Sampling"
                            min={0}
                            max={1}
                            defaultValue={0}
                            validate={[required(), number(), minValue(0), maxValue(1)]} />
            <BooleanInput source="normalize_msisdn" label="Normalize MISDN" validate={required()} defaultValue={true}/>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <StorefrontIcon/>
            <Typography variant="h6">Product Data</Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
            <TextInput source="product_data.merchant_name" label="Mechant Name" fullWidth />
            <TextInput source="product_data.friendly_name" label="Friendly Name" fullWidth />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <SelectInput source="product_data.segment" label="Segment" choices={[
              { id: 'Telecom', name: 'Telecom' },
              { id: 'Utilities', name: 'Utilities' },
              { id: 'Education', name: 'Education' },
              { id: 'Bill Payments', name: 'Bill Payments' }
            ]} />
            <BooleanInput source="product_data.is_cbk_responsible" label="CBK Responsible" />
            <NumberInput source="product_data.net_revenue" label="Net Revenue" min={0} max={1}
              validate={[number(), minValue(0), maxValue(1)]} />
          </Stack>
          <Stack>
            <ArrayInput source="product_data.ec_numbers" label="EC Numbers">
                <SimpleFormIterator fullWidth>
                    <NumberInput validate={[required(), number()]} />
                </SimpleFormIterator>
            </ArrayInput>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LocalOfferIcon/>
            <Typography variant="h6">Tags</Typography>
          </Stack>
          <Stack>
            <ArrayInput source="tags" label="Tags">
                <SimpleFormIterator fullWidth>
                    <TextInput validate={[required()]} />
                </SimpleFormIterator>
            </ArrayInput>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PointOfSaleIcon/>
            <Typography variant="h6">Services-Ids</Typography>
          </Stack>
          <Stack>
            <ArrayInput source="service_ids" label="Service_Ids">
                <SimpleFormIterator fullWidth>
                    <NumberInput validate={[required(), number()]} />
                </SimpleFormIterator>
            </ArrayInput>
          </Stack>
       </Stack>
  )
}

export default DomainInputs
