import * as React from 'react'
import { styled } from '@mui/material/styles'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'

export const NO_PRODUCTS_ROUTE = '/no_products'

export const NoProducts = () => (
  <Root>
    <div className={NotFoundClasses.message}>
        <ProductionQuantityLimitsIcon className={NotFoundClasses.icon} />
        <h1>Oops! No Products Assigned to Your Account.</h1>
        <div>Please contact the <strong>#fraud-prevention-help</strong> Slack channel for assistance with product assignments.</div>
    </div>
  </Root>
)

const PREFIX = 'RaNoProducts'

export const NotFoundClasses = {
  icon: `${PREFIX}-icon`,
  message: `${PREFIX}-message`,
  toolbar: `${PREFIX}-toolbar`
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '100%'
  },
  [theme.breakpoints.down('md')]: {
    height: '100vh',
    marginTop: '-3em'
  },

  [`& .${NotFoundClasses.icon}`]: {
    width: '9em',
    height: '9em'
  },

  [`& .${NotFoundClasses.message}`]: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '0 1em'
  },

  [`& .${NotFoundClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em'
  }
}))
