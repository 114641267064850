import React from 'react'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import RuleIcon from '@mui/icons-material/Rule'
import {
  useListContext,
  EditButton,
  Title,
  ListBase,
  Pagination,
  DeleteWithConfirmButton,
  usePermissions,
  FilterForm
} from 'react-admin'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  CardActions,
  Typography,
  Chip,
  Tooltip,
  Stack
} from '@mui/material'

import { stringAvatar, stringToColor } from 'common/avatar'
import StyledBadge from 'components/styled/Badge'
import RuleListFilter from 'resources/rules/RuleFilter'
import RuleTabs from 'resources/rules/RuleTabs'
import { ButtonContainer, StyledCreateButton, StyledExportButton, StyledFilterButton } from 'styles'

const RuleGrid = () => {
  const { data } = useListContext()
  const { permissions } = usePermissions()
  const isAdmin = permissions.includes('admin')

  if (!data) return null
  return (
    <Grid spacing={2} container>
      {data.map(record => {
        return (
          <Grid item key={record.id} sm={12} md={6} lg={12}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <CardHeader
                title={
                  <Tooltip title="Name">
                    <Typography component="span" variant="h6" noWrap>
                      {record.name}
                    </Typography>
                  </Tooltip>
                }
                avatar={
                  <Avatar
                    sx={{ bgcolor: stringToColor(record.name) }}
                    variant="rounded"
                  >
                    <RuleIcon />
                  </Avatar>
                }
                subheader={
                  <Stack direction="row" spacing={2}>
                    <Tooltip title="Context">
                      <Chip
                        label={record.context}
                        icon={stringAvatar(record.context)}
                      />
                    </Tooltip>
                    <Tooltip title="Relevance">
                      <StyledBadge
                        badgeContent={record.relevance}
                        color="secondary"
                        sx={{ paddingTop: '0.4em' }}
                      >
                        <PrivacyTipIcon />
                      </StyledBadge>
                    </Tooltip>
                    <Tooltip title="Description">
                      <Typography
                        component="span"
                        sx={{ paddingTop: '0.4em' }}
                      >
                        {record.description}
                      </Typography>
                    </Tooltip>
                  </Stack>
                }
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <RuleTabs record={record} />
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                  {isAdmin && (
                    <>
                      <EditButton record={record} />
                      <DeleteWithConfirmButton
                        record={record}
                        confirmTitle={`Delete rule '${record.name}'`}
                      />
                    </>
                  )}
                </CardActions>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  {!isAdmin && record.type === 'custom' && (
                    <>
                      <EditButton record={record} />
                      <DeleteWithConfirmButton
                        record={record}
                        confirmTitle={`Delete rule '${record.name}'`}
                      />
                    </>
                  )}
                </CardActions>

            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

const ListView = () => {
  const { defaultTitle } = useListContext()
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <ButtonContainer>
        <FilterForm filters={RuleListFilter} />
        <StyledFilterButton filters={RuleListFilter} />
        <StyledCreateButton />
        <StyledExportButton />
      </ButtonContainer>
      <RuleGrid />
      <Pagination rowsPerPageOptions={[6, 9, 12]} />
    </>
  )
}

const RuleList = () => (
  <ListBase perPage={6}>
    <ListView />
  </ListBase>
)

export default RuleList
