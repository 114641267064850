import React from 'react'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import {
  useListContext,
  Title,
  Pagination,
  EditButton,
  DeleteWithConfirmButton,
  usePermissions,
  ListBase,
  FilterForm
} from 'react-admin'
import {
  Grid,
  Card,
  CardHeader,
  Typography,
  Tooltip,
  Stack,
  CardActions,
  Chip,
  CardContent
} from '@mui/material'

import product from 'inMemory/product'
import { ButtonContainer, StyledCreateButton, StyledExportButton, StyledFilterButton } from 'styles'
import DomainlistFilters from 'resources/domains/DomainsListFilter'
import DomainTabs from 'resources/domains/DomainTabs'

const DomainGrid = () => {
  const { data } = useListContext()
  const { permissions } = usePermissions()
  const isUser = !permissions.includes('admin')

  if (!data) return null

  return (
    <Grid spacing={2} container>
      {data.map((record) => (
        <Grid item key={record.id} sm={12} md={6} lg={12}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardHeader
              title={(
                <Tooltip title="Alias">
                  <Typography component="span" variant="h5" noWrap>
                    {record.alias}
                  </Typography>
                </Tooltip>
              )}
              subheader={
              <Stack direction="row" spacing={2} style={{ marginTop: '1%', marginBottom: '1%' }}>
                <Tooltip title="Id">
                    <Chip icon={<FingerprintIcon />} label={record.id} color="primary" />
                </Tooltip>
                <Chip
                    icon={record.id === record.domain_id ? <BabyChangingStationIcon /> : <EscalatorWarningIcon />}
                    label={record.id === record.domain_id ? 'parent domain' : 'child domain'}
                    color="warning" />
                  {
                    record.test_ab_conf?.enabled
                      ? <Chip icon={<ToggleOnIcon />} color='error' label='A/B Test' />
                      : null
                  }
              </Stack>}
            />
            <CardContent sx={{ flexGrow: 1 }}>
                <DomainTabs record={record} />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              {!isUser && <EditButton record={record} />}
              {!isUser && <DeleteWithConfirmButton record={record} confirmTitle={`Delete domain '${record.alias}'`} />}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

const ListView = () => {
  const { defaultTitle } = useListContext()
  const { permissions } = usePermissions()
  const isUser = !permissions.includes('admin')

  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <ButtonContainer>
        <FilterForm filters={DomainlistFilters} />
        <StyledFilterButton filters={DomainlistFilters} />
        {!isUser && <StyledCreateButton />}
        <StyledExportButton />
      </ButtonContainer>
      <DomainGrid />
      <Pagination rowsPerPageOptions={[10, 15, 30]} />
    </>
  )
}

const DomainList = () => {
  const { permissions } = usePermissions()
  const filter = permissions.includes('admin') ? {} : { domain_id: product.getDomainId() }

  return (
    <ListBase perPage={10} filter={filter}>
      <ListView />
    </ListBase>
  )
}

export default DomainList
