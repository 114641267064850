import React from 'react'
import { Grid } from '@mui/material'
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  required,
  ReferenceInput,
  usePermissions
} from 'react-admin'

import useOnError from 'common/error'
import product from 'inMemory/product'
import DomainIdAutoComplete from 'components/DomainIdAutoComplete'
import ActionSelect from 'resources/blocklists/ActionSelect'

const transform = data => {
  if (data.expired_at === '') {
    data.expired_at = null
  }
  return data
}

const BlocklistCreate = props => {
  const onError = useOnError()
  const { permissions } = usePermissions()
  const filter = permissions.includes('admin') ? null : { id: product.getId() }
  return (
    <Create mutationOptions={{ onError }} transform={transform}>
        <SimpleForm>
            <ReferenceInput
              source="domain_id"
              reference="domains"
              filter={filter}
              perPage={10000}
              >
              <DomainIdAutoComplete fullWidth validate={required()} optionText="alias" optionValue='domain_id' />
            </ReferenceInput>
            <TextInput source="key" validate={required()} fullWidth/>
            <TextInput source="value" validate={required()} fullWidth/>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                  <ActionSelect
                    source="action" fullWidth
                    validate={required()}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <DateTimeInput source="expired_at" fullWidth/>
              </Grid>
              <Grid item xs={12} sm={4}>
                  <DateTimeInput
                    source="created_at" fullWidth
                    defaultValue={new Date().toISOString()}
                    validate={required()}
                  />
              </Grid>
            </Grid>
        </SimpleForm>
    </Create>
  )
}

export default BlocklistCreate
