/**
 * Configuration settings for different environments.
 *
 * This module defines a configuration object containing settings for production, staging, and default environments.
 * The environment is determined based on the value of the REACT_APP_ENV environment variable, falling back to
 * the default environment if not set or invalid.
 */

const config = {
  production: {
    cognito_userPoolId: 'us-east-1_S2l5JCvp0',
    cognito_clientId: '3muhr58dabsfm7b2d99p59fbkp',
    cognito_hostedUIUrl: 'https://betrusty-auth-prd2.auth.us-east-1.amazoncognito.com',
    apiUrl: 'https://l6q43vqmqb-vpce-0885831eb728f05fb.execute-api.us-east-1.amazonaws.com/prd2'
  },
  staging: {
    cognito_userPoolId: 'us-east-1_HUTDzpRBx',
    cognito_clientId: '1qgqtnf4006fovbn9m8s6bprlq',
    cognito_hostedUIUrl: 'https://betrusty-auth-stg.auth.us-east-1.amazoncognito.com',
    apiUrl: 'https://w9xg5m3av9-vpce-0bc93940c33365e26.execute-api.us-east-1.amazonaws.com/stg'
  },
  default: { // dev
    cognito_userPoolId: 'us-east-1_G8ALxgCry',
    cognito_clientId: '1musspg99uk2j3lp8orsofpk2t',
    cognito_hostedUIUrl: 'https://betrusty-auth-dev.auth.us-east-1.amazoncognito.com',
    apiUrl: 'http://localhost:8000'
  }
}

const environment = config[process.env.REACT_APP_ENV] || config.default

export const userPoolId = environment.cognito_userPoolId
export const clientId = environment.cognito_clientId
export const hostedUIUrl = environment.cognito_hostedUIUrl
export const apiUrl = environment.apiUrl
