import * as React from 'react'
import { styled } from '@mui/material'
import { AutocompleteInput } from 'react-admin'

const DomainIdAutoComplete = props => {
  const filterToQuery = searchText => ({ alias: `${searchText}` })

  return (
      <AutoCompleteInputStyled
        {...props}
        filterToQuery={filterToQuery}
        optionValue='id'
        optionText="alias"
      />
  )
}

export const AutoCompleteInputStyled = styled(AutocompleteInput)`
  width: 36vw;
`

export default DomainIdAutoComplete
