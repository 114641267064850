import { React, useState, forwardRef } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  Tooltip,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Button
} from '@mui/material'
import { useDataProvider, useNotify, useRefresh } from 'react-admin'
import { useMutation } from 'react-query'

import RuleList from 'resources/rule_books/RuleList'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddRuleButton = (props) => {
  const { book } = props
  const [open, setOpen] = useState(false)

  const handleDialogOpen = e => {
    setOpen(true)
  }

  const handleDialogClose = e => {
    setOpen(false)
  }

  const [selectedRules, setSelectedRules] = useState([])

  const handleSelect = ids => {
    setSelectedRules(ids)
  }

  const notify = useNotify()
  const refresh = useRefresh()

  const dataProvider = useDataProvider()
  const providerParams = { bookId: book.id, rules: selectedRules }
  const { mutate, isLoading } = useMutation(
    () => dataProvider.addRule(providerParams),
    {
      onSettled: (data, error, variables, context) => {
        setOpen(false)
        if (error == null) {
          notify('Rules added successfully', { type: 'info' })
        } else {
          notify(error.body.details, { type: 'error' })
        }
        refresh()
      }
    }
  )

  const handleAddRules = e => {
    e.stopPropagation()
    mutate()
  }

  return (<>
    <Tooltip title="Add">
      <IconButton color="primary" onClick={handleDialogOpen} >
          <AddCircleOutlineIcon />
      </IconButton>
    </Tooltip>
    <Dialog
      fullScreen
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`Add Rule to Rule Book '${book.name}'`}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleAddRules} disabled={isLoading}>
              Add
            </Button>
          </Toolbar>
        </AppBar>
        <RuleList onSelectRules={handleSelect} />
    </Dialog>
  </>)
}

export default AddRuleButton
