import React from 'react'
import { Grid } from '@mui/material'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  number,
  minValue,
  maxValue,
  regex,
  usePermissions
} from 'react-admin'

import useOnError from 'common/error'
import product from 'inMemory/product'
import DomainIdAutoComplete from 'components/DomainIdAutoComplete'

const floatPriorityMsg = '`Priority` should be a positive number ranging from 1 to 1000'

export const BookCreate = props => {
  const onError = useOnError()
  const { permissions } = usePermissions()
  const filter = permissions.includes('admin') ? null : { id: product.getId() }

  return (
    <Create mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput source="name" multiline validate={required()} fullWidth />
        <TextInput source="description" multiline validate={required()} fullWidth />
        <TextInput source="scope" multiline validate={required()} fullWidth />
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>

            <ReferenceInput
              reference="domains"
              filter={filter}
              perPage={10000}
              source='domain_id'
            >
              <DomainIdAutoComplete />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="priority"
              fullWidth
              validate={[required(), number(), minValue(1), maxValue(1000), regex(/^[0-9]*$/, floatPriorityMsg)]}
            />
          </Grid>
        </Grid>
        <ArrayInput source="parameters">
          <SimpleFormIterator inline>
            <TextInput source="k" fullWidth label="name" helperText={false} validate={required()} />
            <TextInput source="v" label="value" helperText={false} validate={required()} />
            <SelectInput
              source="type"
              choices={[
                { id: 'int', name: 'int' },
                { id: 'float', name: 'float' },
                { id: 'str', name: 'str' }
              ]}
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default BookCreate
