import * as React from 'react'
import { ReferenceInput } from 'react-admin'
import FilterInput from 'components/styled/FilterInput'
import DomainIdAutoComplete from 'components/DomainIdAutoComplete'
import ActionSelect from 'resources/blocklists/ActionSelect'

const BlocklistFilters = [
    <ReferenceInput key="domain" source="domain_id" reference="domains" perPage={10000} >
      <DomainIdAutoComplete optionText="alias" />
    </ReferenceInput>,
    <FilterInput key="key" label="Key" source="key" />,
    <FilterInput key="value" label="Value" source="value" />,
    <ActionSelect key="action" label="Action" source="action" />
]

export default BlocklistFilters
