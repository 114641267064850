import BlockListIcon from '@mui/icons-material/BlockSharp'

import BlocklistList from 'resources/blocklists/BlocklistList'
import BlocklistEdit from 'resources/blocklists/BlocklistEdit'
import BlocklistCreate from 'resources/blocklists/BlocklistCreate'

const resource = {
  list: BlocklistList,
  edit: BlocklistEdit,
  create: BlocklistCreate,
  icon: BlockListIcon
}

export default resource
