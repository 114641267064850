import jwtDecode from 'jwt-decode'

const idToken = () => {
  let inMemoryIdToken = null

  const getToken = () => inMemoryIdToken

  const setToken = (token) => {
    try {
      inMemoryIdToken = jwtDecode(token)
    } catch (error) {
    }
  }

  const ereaseToken = () => {
    inMemoryIdToken = null
  }

  return {
    ereaseToken,
    getToken,
    setToken
  }
}

export default idToken()
