import React from 'react'
import { Create, SimpleForm } from 'react-admin'

import useOnError from 'common/error'
import DomainInputs from 'resources/domains/DomainInputs'

const DomainCreate = props => {
  const onError = useOnError()
  return (
  <Create mutationOptions={{ onError }}>
      <SimpleForm>
        <DomainInputs view="create" />
      </SimpleForm>
  </Create>
  )
}

export default DomainCreate
