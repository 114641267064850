/**
 * Provides authentication services using Amazon Cognito.
 * @module authProvider
 */
import {
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoUser
} from 'amazon-cognito-identity-js'
import { userPoolId, clientId, hostedUIUrl } from 'config'

import idToken from 'inMemory/idToken'
import accessToken from 'inMemory/accessToken'

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId
})

/* Redirects the browser to the Cognito hosted UI for authentication. */
const redirectToOAuth = () => {
  const redirectUri = `${window.location.origin}/auth-callback`
  const scope = ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin']

  const url = `${hostedUIUrl}/login?client_id=${clientId}&response_type=token&scope=${
        scope.join('+')}&redirect_uri=${redirectUri}`
  window.location.href = url
}

/** Loads the access and ID tokens from storage and updates the in-memory tokens. */
const loadTokensFromStorage = (user) => {
  if (user.storage) {
    for (const [key, value] of Object.entries(user.storage)) {
      if (key.includes('.accessToken')) {
        accessToken.setToken(value)
      } else if (key.includes('.idToken')) {
        idToken.setToken(value)
      }
    }
  }
}

const authProvider = {
  getIdentity: () => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser()
      if (!user) {
        return reject()
      }

      user.getSession((err, session) => {
        if (err) {
          return reject(err)
        }
        if (!session.isValid()) {
          return reject()
        }
        accessToken.setToken(session.accessToken.jwtToken)
        user.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err)
          }
          resolve({
            id: user.getUsername(),
            fullName: attributes?.find(
              attribute => attribute.Name === 'name'
            )?.Value,
            avatar: attributes?.find(
              attribute => attribute.Name === 'picture'
            )?.Value,
            products: attributes?.find(
              (attribute) => attribute.Name === 'custom:products'
            )?.Value
          })
        })
      })
    })
  },
  logout () {
    return new Promise(resolve => {
      const user = userPool.getCurrentUser()
      if (user) {
        user.signOut(() => {
          resolve()
        })
      }
      accessToken.ereaseToken()
      idToken.ereaseToken()
      redirectToOAuth()
    })
  },
  checkError: ({ status }) => new Promise((resolve, reject) => {
    if (status === 401 || status === 403) {
      reject(new Error('Unauthorized'))
    }
    resolve()
  }),
  getPermissions () {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser()
      if (!user) {
        return reject()
      }
      user.getSession((err, session) => {
        if (err) {
          return reject(err)
        }
        if (!session.isValid()) {
          return reject()
        }
        accessToken.setToken(session.getAccessToken().getJwtToken())
        const token = session.getIdToken().decodePayload()
        return resolve(token['cognito:groups'] ?? [])
      })
    })
  },
  /* Checks if the user is authenticated and refreshes the session tokens if necessary. */
  checkAuth: () => new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser()
    if (!user) {
      reject()
    }
    loadTokensFromStorage(user)

    user.getSession((err, session) => {
      if (err || !session.isValid()) {
        reject()
      }
      user.getUserAttributes(err => {
        if (err) {
          reject(err)
        }
        accessToken.setToken(session.getAccessToken().getJwtToken())
        resolve()
      })
    })
  }),
  /* Handles the OAuth callback by parsing the URL parameters to obtain tokens
  and setting up the user session. */
  handleCallback: () => new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(
      window.location.hash.substring(1)
    )
    const error = urlParams.get('error')
    const errorDescription = urlParams.get('error_description')

    if (error) {
      reject(errorDescription)
    }
    const returnedAccessToken = urlParams.get('access_token')
    const returnedIdToken = urlParams.get('id_token')

    if (returnedIdToken == null || returnedAccessToken == null) {
      reject()
    }
    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: returnedIdToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: null
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: returnedAccessToken
      })
    })

    const user = new CognitoUser({
      Username: session.getIdToken().decodePayload()[
        'cognito:username'
      ],
      Pool: userPool,
      Storage: window.localStorage
    })
    user.setSignInUserSession(session)
    accessToken.setToken(returnedAccessToken)
    resolve(user)
  })

}

export default authProvider
