import React from 'react'
import { ReferenceInput } from 'react-admin'
import FilterInput from 'components/styled/FilterInput'
import DomainIdAutoComplete from 'components/DomainIdAutoComplete'

const BookFilters = [
  <ReferenceInput key="domain" source="domain_id" reference="domains" perPage={10000}>
      <DomainIdAutoComplete optionText="alias" />
  </ReferenceInput>,
  <FilterInput key="name" label="Name" source="name" fullWidth />

]

export default BookFilters
