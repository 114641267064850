import React from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import CellTowerIcon from '@mui/icons-material/CellTower'

export const stringToColor = (string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (ctx) => {
  const isUtilities = ctx.toLowerCase() === 'utilities'
  if (isUtilities) {
    return (<ElectricBoltIcon/>)
  } else {
    return (<CellTowerIcon/>)
  }
}
