import React from 'react'
import FilterInput from 'components/styled/FilterInput'

const DomainlistFilters = [
    <FilterInput key="alias" label="alias" source="alias" multiline/>,
    <FilterInput key="company" label="company" source="company" multiline/>,
    <FilterInput key="workspace" label="workspace" source="workspace" multiline/>,
    <FilterInput key="application" label="application" source="application" multiline/>
]

export default DomainlistFilters
