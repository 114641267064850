import React from 'react'
import {
  CardHeader,
  Typography,
  Tooltip,
  Avatar,
  Stack,
  Chip
} from '@mui/material'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import HttpOutlinedIcon from '@mui/icons-material/HttpOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'

import { stringToColor } from 'common/avatar'
import LocaleDateField from 'components/LocaleDateField'
import StyledBadge from 'components/styled/Badge'

const EventsHeader = ({ record }) => {
  const hasEmail = record.user !== null && record.user.email !== null
  const email = hasEmail ? record.user.email : 'rule.engine@bemobi.com'

  return (<CardHeader
    title={(
      <Tooltip title="User">
        <Typography component="span" variant="h6" noWrap>
          <span style={{ fontWeight: 'bold' }}>
            {email.split('@')[0]}
          </span>
          {` ${record.command}d ${record.resource.type.slice(0, -1)} '${record.resource.id}'`}
        </Typography>
      </Tooltip>
    )}
    avatar={
      <Avatar
        sx={{ bgcolor: stringToColor(email) }}
        src="/"
        alt={email.toUpperCase()}
      />
    }
    subheader={
      <Stack direction="row" spacing={2}>
          <LocaleDateField source="ocurred_at" locales="pt-BR" showTime record={record}/>
          <StyledBadge
              badgeContent={record.source.type === 'rule_engine' ? 'RE' : record.source.type}
              color="secondary"
              sx={{ paddingTop: '0.4em' }}
            >
              {record.source.type === 'api' ? <LanguageOutlinedIcon /> : <SettingsOutlinedIcon />}
          </StyledBadge>
          <Chip
            label={`${record.source.http_method} ${record.source.url}`}
            icon={<HttpOutlinedIcon />}
           />
           {hasEmail && (
            <Chip
              label={email}
              icon={<PermIdentityOutlinedIcon />}
            />
           )}
      </Stack>
    }
  />)
}

export default EventsHeader
