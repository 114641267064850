import React from 'react'
import FilterInput from 'components/styled/FilterInput'

const RuleListFilter = [
    <FilterInput key="name" label="Name" source="name" />,
    <FilterInput key="actions" label="Actions" source="actions" />,
    <FilterInput key="attributes" label="Attributes" source="attributes" />,
    <FilterInput key="functions" label="Functions" source="functions" />,
    <FilterInput key="parameters" label="Parameters" source="parameters" />

]
export default RuleListFilter
