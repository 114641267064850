import React from 'react'
import TuneIcon from '@mui/icons-material/Tune'
import PowerIcon from '@mui/icons-material/PowerSettingsNew'
import RuleIcon from '@mui/icons-material/RuleSharp'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton
} from '@mui/material'
import {
  ArrayField,
  Datagrid,
  TextField,
  ReferenceArrayField,
  useRedirect,
  WithRecord,
  useCreatePath
} from 'react-admin'

import { CodeBlockField } from 'common/fields'
import RemoveRuleButton from 'resources/rule_books/RemoveRuleButton'
import AddRuleButton from 'resources/rule_books/AddRuleButton'
import { a11yProps, TabPanel } from 'components/TabPanel'
import { StyledLink } from 'components/styled/Link'

const AddParameterButton = (props) => {
  const { book } = props
  const redirect = useRedirect()
  const handleClick = () => {
    redirect('edit', 'rule_books', book.id)
  }
  return (
    <Tooltip title="Add">
        <IconButton color="primary" onClick={handleClick} >
            <AddCircleOutlineIcon />
        </IconButton>
    </Tooltip>
  )
}

const RulesDatagridHeader = (props) => {
  const { record } = props
  return (
  <TableHead>
      <TableRow>
            <TableCell/>
            <TableCell>
              <AddRuleButton book={record}/>
            </TableCell>
      </TableRow>
  </TableHead>
  )
}

const ParametersDatagridHeader = (props) => {
  const { record } = props
  return (
  <TableHead>
      <TableRow>
            <TableCell>
              <AddParameterButton book={record}/>
            </TableCell>
            <TableCell/>
            <TableCell/>
      </TableRow>
  </TableHead>
  )
}

const BookTabs = ({ record }) => {
  const createPath = useCreatePath()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Scope" icon={<PowerIcon />} iconPosition="start" {...a11yProps(0)} />
          <Tab label="Parameters" icon={<TuneIcon/>} iconPosition="start" {...a11yProps(1)} />
          <Tab label="Rules" icon={<RuleIcon />} iconPosition="start" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          <CodeBlockField record={record} source="scope"/>
      </TabPanel>
      <TabPanel value={value} index={1}>
          <ArrayField source="parameters" label="" record={record}>
            <Datagrid
                bulkActionButtons={false}
                header={<ParametersDatagridHeader record={record} />}
                empty={<AddParameterButton book={record}/>}
            >
              <TextField source="k" label=""/>
              <TextField source="v" label=""/>
              <TextField source="type" label=""/>
            </Datagrid>
          </ArrayField>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReferenceArrayField reference="rules" source="rules" label="" record={record} >
          <Datagrid
              bulkActionButtons={false}
              header={<RulesDatagridHeader record={record}/>}
              empty={<AddRuleButton book={record}/>}>
              <WithRecord render={record =>
                 <StyledLink
                  to={createPath({
                    resource: 'rules', id: record.id, type: 'edit'
                  })}
                  >
                   <Typography sx={{ color: '#1976d2' }} variant='span'>{record.name}</Typography>
                </StyledLink>
              } />
              <RemoveRuleButton book={record} />
          </Datagrid>
        </ReferenceArrayField>
      </TabPanel>
    </Box>
  )
}

export default BookTabs
