import React from 'react'
import BookIcon from '@mui/icons-material/AutoStoriesTwoTone'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import DomainIcon from '@mui/icons-material/LanguageSharp'
import {
  useListContext,
  List,
  Pagination,
  EditButton,
  ReferenceField,
  ChipField,
  DeleteWithConfirmButton,
  usePermissions
} from 'react-admin'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tooltip,
  Typography,
  Avatar,
  Stack
} from '@mui/material'

import { stringToColor } from 'common/avatar'
import StyledBadge from 'components/styled/Badge'
import BookTabs from 'resources/rule_books/BookTabs'
import BookFilters from 'resources/rule_books/BookFilters'
import product from 'inMemory/product'

const BookGrid = () => {
  const { data } = useListContext()

  if (!data) return null
  return (
    <Grid spacing={2} container>
      {data.map(record => (
          <Grid item key={record.id} sm={12} md={6} lg={12}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <CardHeader
                title={
                  <Tooltip title="Name">
                    <Typography component="span" variant="h6" noWrap>
                      {record.name}
                    </Typography>
                  </Tooltip>
                }
                avatar={
                  <Avatar sx={{ bgcolor: stringToColor(record.name) }} variant="rounded">
                    <BookIcon />
                  </Avatar>
                }
                subheader={
                  <Stack direction="row" spacing={2}>
                    <Tooltip title="Domain">
                      <ReferenceField source="domain_id" reference="domains" record={record}>
                          <ChipField source='alias' icon={<DomainIcon />}></ChipField>
                      </ReferenceField>
                    </Tooltip>
                    <Tooltip title="Priority">
                      <StyledBadge badgeContent={record.priority} color="secondary" sx={{ paddingTop: '0.4em' }}>
                        <NewReleasesIcon />
                      </StyledBadge >
                    </Tooltip>
                    <Tooltip title="Description">
                      <Typography component="span" sx={{ paddingTop: '0.4em' }}>
                          {record.description}
                      </Typography>
                    </Tooltip>
                  </Stack>
                }
              />
              <CardContent>
                <BookTabs record={record} />
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <EditButton record={record} />
                <DeleteWithConfirmButton record={record}
                  confirmTitle={`Delete rule book '${record.name}'`} />
              </CardActions>
            </Card>
          </Grid>
      ))}
    </Grid>
  )
}

const BookList = () => {
  const { permissions } = usePermissions()
  const filter = permissions.includes('admin') ? {} : { domain_id: product.getDomainId() }

  return (
    <List
      filters={BookFilters}
      perPage={6}
      pagination={<Pagination rowsPerPageOptions={[6, 9, 12]} />}
      filter={filter}
      sx={{
        '& .RaList-content': {
          backgroundColor: '#fafafb',
          boxShadow: 'none'
        }
      }}
    >
      <BookGrid />
    </List>
  )
}

export default BookList
