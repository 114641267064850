import * as React from 'react'
import { Grid } from '@mui/material'
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  required
} from 'react-admin'

import BlocklistName from 'resources/blocklists/BlocklistName'
import useOnError from 'common/error'
import ActionSelect from 'resources/blocklists/ActionSelect'
import DomainIdEditorSelect from 'components/DomainIdEditorSelect'

const transform = data => {
  if (data.expired_at === '') {
    data.expired_at = null
  }
  return data
}

const BlocklistEdit = () => {
  const onError = useOnError()

  return (
    <Edit title={<BlocklistName />} mutationOptions={{ onError }} transform={transform}>
      <SimpleForm>
        <DomainIdEditorSelect fullWidth validate={required()} optionText="name" optionValue="id" />
        <TextInput source="key" validate={required()} fullWidth />
        <TextInput source="value" validate={required()} fullWidth />
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <ActionSelect
              source="action" fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateTimeInput source="expired_at" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateTimeInput
              source="created_at" fullWidth
              defaultValue={new Date().toISOString()}
              validate={required()}
            />
          </Grid>
        </Grid>
        <TextInput source="id" fullWidth disabled />
      </SimpleForm>
    </Edit>
  )
}

export default BlocklistEdit
