import React from 'react'
import { FilterLiveSearch, FilterList, FilterListItem } from 'react-admin'
import {
  Card,
  CardContent
} from '@mui/material'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'

const EventsSidebar = () => (
  <Card
    sx={{
      display: { xs: 'none', md: 'block' },
      order: -1,
      width: '15em',
      mr: 2,
      alignSelf: 'flex-start'
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch label='Resouce Id' source="resource.id" />
      <FilterLiveSearch label='User Email' source="user.email" />
      <FilterList label="Occurred at" icon={<AccessTimeOutlinedIcon />}>
      <FilterListItem
          label="Last 24 Hours"
          value={{ ocurred_at: '24h' }}
      />
      <FilterListItem
          label="Last 7 Days"
          value={{ ocurred_at: '7D' }}
      />
      <FilterListItem
          label="Last 30 Days"
          value={{ ocurred_at: '90D' }}
      />
      </FilterList>
      <FilterList label="Resources" icon={<DataObjectOutlinedIcon />}>
        <FilterListItem label="Domains" value={{ 'resource.type': 'domains' }} />
        <FilterListItem label="Rules" value={{ 'resource.type': 'rules' }} />
        <FilterListItem label="Rule Books" value={{ 'resource.type': 'rule_books' }} />
        <FilterListItem label="Blocklists" value={{ 'resource.type': 'blocklists' }} />
      </FilterList>
      <FilterList label="Actions" icon={<DirectionsRunOutlinedIcon />}>
        <FilterListItem label="Create" value={{ command: 'create' }} />
        <FilterListItem label="Update" value={{ command: 'update' }} />
        <FilterListItem label="Delete" value={{ command: 'delete' }} />
      </FilterList>

    </CardContent>

  </Card>
)

export default EventsSidebar
