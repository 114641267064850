import * as React from 'react'
import {
  SelectInput,
  useRecordContext,
  useDataProvider
} from 'react-admin'

const DomainIdEditorSelect = props => {
  const record = useRecordContext()
  const [domainOptions, setDomainOptions] = React.useState([])
  const dataProvider = useDataProvider()

  React.useEffect(() => {
    if (record && record.domain_id) {
      dataProvider.getOne('domains', { id: record.domain_id })
        .then(({ data }) => {
          setDomainOptions([{ id: data.id, name: data.alias }])
        })
        .catch(error => {
          throw new Error('Cannot get domain_id', error)
        })
    }
  }, [record, dataProvider])

  return (
    <SelectInput {...props} source="domain_id" choices={domainOptions} disabled/>
  )
}

export default DomainIdEditorSelect
