import React from 'react'
import { SelectInput } from 'react-admin'

const ActionSelect = props => {
  const choises = [
    { id: 'block', name: 'Block' },
    { id: 'allow', name: 'Allow' },
    { id: 'allow_if_not_in_blocklist', name: 'Allow if Not in Blocklist' }
  ]

  return (
    <SelectInput {...props} choices={choises}/>
  )
}

export default ActionSelect
