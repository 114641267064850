import { styled } from '@mui/system'
import { FilterLiveSearch } from 'react-admin'

export const Container = styled('div')`
  width: 500px;
  padding: 1em;
  border-left: 1px solid #ccc;
`
export const ContainerTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
`
export const FilterLiveStyled = styled(FilterLiveSearch)`
  width: 500px;
`
