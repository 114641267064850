import React from 'react'
import {
  ListBase,
  Title,
  useListContext,
  Pagination,
  FilterForm,
  ReferenceField,
  ChipField
} from 'react-admin'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Box,
  Stack
} from '@mui/material'
import {
  FingerprintOutlined,
  Verified,
  ThumbUpAlt,
  ThumbDownAlt,
  CalendarMonth,
  Domain,
  Block,
  CreditCardOff,
  MoreHoriz
} from '@mui/icons-material'

import StyledBadge from 'components/styled/Badge'
import LocaleDateField from 'components/LocaleDateField'
import AnalysesTabs from 'resources/analyses/AnalysesTab'
import { ButtonContainer, StyledFilterButton } from 'styles'
import AnalysesFilters from 'resources/analyses/AnalysesFilters'

const statusIcon = (status) => {
  switch (status) {
    case 'OK':
      return <ThumbUpAlt />
    case 'NOK':
      return <ThumbDownAlt />
    case 'CBK':
      return <CreditCardOff />
    default:
      return <MoreHoriz />
  }
}

const statusColor = (status) => {
  switch (status) {
    case 'OK':
      return 'success'
    case 'NOK':
      return 'error'
    case 'CBK':
      return 'warning'
    default:
      return 'info'
  }
}

const AnalysesView = () => {
  const { data } = useListContext()
  if (!data) return null
  return (
    <Grid spacing={2} container>
      {
        data.map((record) => {
          const status = record.context.transaction.status
          const resultIcon = record.result === 'authorized' ? <Verified /> : <Block />

          return (
            <Grid item key={record.id} sm={12} md={6} lg={12}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <CardHeader
                 title={
                  <Stack spacing={1} direction="row" sx={{ paddingBottom: 1 }}>
                    <StyledBadge badgeContent="id" color='error' sx={{ fontSize: '32px' }}>
                      <FingerprintOutlined sx={{ fontSize: 35 }} />
                    </StyledBadge>
                    <Chip label={record.id} style={{ marginLeft: 4, fontSize: 20 }}/>
                  </Stack>
                 }
                 subheader={
                  <Stack spacing={1} direction="row">
                     <Chip icon={resultIcon} label={record.result} color="primary" style={{ marginLeft: 4 }}/>
                     <Chip icon={statusIcon(status)} label={status} color={statusColor(status)} style={{ marginLeft: 4 }}/>
                     <Box display="flex" alignItems="center" gap={1}>
                          <CalendarMonth/>
                          <LocaleDateField source="created_at" locales="pt-BR" showTime record={record} />
                      </Box>
                      <ReferenceField source="context.domain_id" reference="domains" record={record}>
                          <ChipField source='alias' icon={<Domain />}></ChipField>
                      </ReferenceField>
                  </Stack>
                 }
                />
                <CardContent sx={{ flexGrow: 1 }} >
                  <AnalysesTabs record={record} />
                </CardContent>
              </Card>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

const AnalysesList = () => {
  const { defaultTitle } = useListContext()
  return (
  <ListBase>
    <Title defaultTitle={defaultTitle} />
    <ButtonContainer>
      <FilterForm filters={AnalysesFilters} />
      <StyledFilterButton filters={AnalysesFilters} />
    </ButtonContainer>
    <AnalysesView />
    <Pagination rowsPerPageOptions={[10, 15, 20]} />
  </ListBase>
  )
}

export default AnalysesList
